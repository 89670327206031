/**
 * Converts a height in centimeters to feet and inches.
 *
 * @param {number} cm - The height in centimeters.
 * @returns {Object} An object containing the height in feet and inches.
 */
export const fromCmToFeet = (cm) => {
  const totalInches = cm / 2.54;
  const feet = Math.floor(totalInches / 12);
  const inches = Math.round(totalInches % 12);
  return { feet, inches };
};

/**
 * Converts a weight in kilograms to pounds.
 *
 * @param {number} kg - The weight in kilograms.
 * @returns {Object} An object containing the weight in kilograms and pounds.
 */
export const fromKgToLbs = (kg) => {
  const lbs = Math.round(kg * 2.20462);
  return { kg, lbs };
};

/**
 * Determine BMI Range based on BMI value.
 *
 * @param {number} bmi - The calculated BMI value.
 * @returns {string} - The BMI range as a string.
 */
export const determineBmiRange = (bmi) => {
  const BMI_RANGES = {
    UNDERWEIGHT: 'Underweight',
    NORMAL: 'Normal',
    OVERWEIGHT: 'Overweight',
    OBESITY_TYPE_1: 'Obesity Type 1',
    OBESITY_TYPE_2: 'Obesity Type 2',
    OBESITY_TYPE_3: 'Obesity Type 3',
  };

  if (bmi >= 18.5 && bmi < 25) {
    return BMI_RANGES.NORMAL;
  } else if (bmi >= 25 && bmi < 30) {
    return BMI_RANGES.OVERWEIGHT;
  } else if (bmi >= 30 && bmi < 35) {
    return BMI_RANGES.OBESITY_TYPE_1;
  } else if (bmi >= 35 && bmi < 40) {
    return BMI_RANGES.OBESITY_TYPE_2;
  } else if (bmi >= 40) {
    return BMI_RANGES.OBESITY_TYPE_3;
  } else {
    return BMI_RANGES.UNDERWEIGHT;
  }
};

/**
 * Determine BMI Range description based on BMI value.
 *
 * @param {number} bmi - The calculated BMI value.
 * @returns {string} - The BMI range description as a string.
 */
export const determineBmiDescription = (bmi) => {
  const BMI_RANGES = {
    UNDERWEIGHT: `Being underweight, with a BMI below 18.5, can lead to a weakened immune system, fragile bones, and 
            nutrient deficiencies, making it harder for your body to fight infections and recover from illness. 
            Compared to the normal weight range, underweight individuals might struggle with energy levels and 
            overall health. Our platform offers customized dietary plans and workout routines to help you gain
             weight healthily, ensuring you receive the necessary nutrients to build muscle, improve your 
             immune function, and enhance overall well-being.`,
    NORMAL: `A normal BMI, ranging from 18.5 to 24.9, indicates a healthy weight. Maintaining this balance reduces the 
            risk of chronic diseases like heart disease and diabetes, enhancing overall well-being and longevity. 
            Compared to being underweight, normal weight individuals typically have better energy levels and 
            immune function. Unlike those who are overweight or obese, they have a lower risk of developing 
            weight-related health issues. Our diverse range of diets, workouts, and tracking tools will help 
            you sustain this healthy weight, optimize your fitness level, and improve your quality of life.`,
    OVERWEIGHT: `Being overweight, with a BMI between 25 and 29.9, means your weight is higher than the normal range but 
            not yet in the obesity category. This can increase the risk of heart disease, diabetes, and other 
            health issues compared to those with a normal BMI. Overweight individuals may experience more strain 
            on joints and potential metabolic issues than those in the normal weight range but are not as 
            severely impacted as those in the obesity categories. Our personalized diet plans, exercise routines, 
            and progress tracking can assist you in losing weight safely and effectively, reducing these health 
            risks and improving your overall fitness.`,
    OBESITY_TYPE_1: `Obesity Type 1, with a BMI between 30 and 34.9, indicates moderate obesity. This category heightens 
            the risk of cardiovascular diseases, diabetes, and joint problems compared to being overweight or having 
            a normal BMI. Individuals in this category face more significant health challenges than those in the 
            overweight category but are at a lower risk than those in the more severe obesity categories. Our 
            comprehensive approach, combining diet plans, workout regimens, and continuous monitoring, helps you 
            lose weight and mitigate these health risks, improving your quality of life.`,
    OBESITY_TYPE_2: `Obesity Type 2, characterized by a BMI between 35 and 39.9, signifies severe obesity. This significantly 
            increases the risk of serious health problems like heart disease, stroke, and certain cancers compared 
            to those in the Obesity Type 1 and overweight categories. The health risks and challenges are more pronounced, 
            necessitating a more intensive approach to weight loss and health improvement. We provide intensive support, 
            including customized diets, exercise plans, and regular health tracking, to aid in significant weight loss and 
            enhance overall health.`,
    OBESITY_TYPE_3: `Obesity Type 3, or morbid obesity, represents the highest BMI category, with severe health risks including
            heart disease, diabetes, and reduced life expectancy. Our platform offers specialized, medically-informed
            diet and exercise plans, along with professional guidance, to support substantial weight loss and enhance
            overall health.`,
  };

  if (bmi >= 18.5 && bmi < 25) {
    return BMI_RANGES.NORMAL;
  } else if (bmi >= 25 && bmi < 30) {
    return BMI_RANGES.OVERWEIGHT;
  } else if (bmi >= 30 && bmi < 35) {
    return BMI_RANGES.OBESITY_TYPE_1;
  } else if (bmi >= 35 && bmi < 40) {
    return BMI_RANGES.OBESITY_TYPE_2;
  } else if (bmi >= 40) {
    return BMI_RANGES.OBESITY_TYPE_3;
  } else {
    return BMI_RANGES.UNDERWEIGHT;
  }
};

/**
 * Calculate BMI from weight and height.
 *
 * @param {number} weight - The weight of the user in kilograms.
 * @param {number} height - The height of the user in centimeters.
 * @returns {number} - The calculated BMI value.
 */
export const calculateBmi = (weight, height) => {
  return weight / Math.pow(height / 100, 2);
};
