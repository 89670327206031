import styled from 'styled-components';

export const StyledPlansComparisonTable = styled.div`
  .plans-comparison-table {
    margin: 0 auto;
    max-width: 1250px;
    padding-top: 60px;

    &__heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      gap: 32px;
      margin-bottom: 50px;
    }

    table {
      .ant-table-cell {
        border-right: 1px solid ${({ theme }) => theme.colors.alto};

        &:first-child {
          border-left: 1px solid ${({ theme }) => theme.colors.alto};
        }
      }

      thead {
        tr {
          th {
            border-top: 1px solid ${({ theme }) => theme.colors.alto};

            &:first-child {
              background-color: transparent;
              border-top: none;
              border-left: none !important;
              border-right: none;
            }

            &:nth-child(2) {
              border-left: 1px solid ${({ theme }) => theme.colors.alto};
              border-top-left-radius: 8px;
            }

            &::before {
              content: none !important;
            }

            &:not(:first-child) {
              text-align: center;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &:first-child {
              border-right: 0;
            }

            &:nth-child(2) {
              border-left: 1px solid ${({ theme }) => theme.colors.alto};
            }

            &:not(:first-child) {
              text-align: center;
            }
          }

          &:last-child {
            td {
              &:first-child {
                border-bottom: none;
                border-left: none;
              }

              &:nth-child(2) {
                border-bottom-left-radius: 8px;
              }

              &:last-child {
                border-bottom-right-radius: 8px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .plans-comparison-table {
    }
  }
`;
