import React from 'react';

import { StyledHoverScaleImage } from './styles';

const HoverScaleImage = ({ source, width = '300px', height = '220px' }) => {
  return (
    <StyledHoverScaleImage width={width} height={height}>
      <img src={source} />
    </StyledHoverScaleImage>
  );
};

export default HoverScaleImage;
