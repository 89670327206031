import React, { useCallback, useState } from 'react';

import { Form } from 'antd';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';

import { BmiGauge } from '@src/components/atoms/bmi-gauge';
import { MeasurementPicker } from '@src/components/atoms/form/measurement-picker';
import { GradientButton } from '@src/components/atoms/gradient-button';
import { selectOnboardingUserData } from '@src/store/slices';
import { Heading11, Paragraph1 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';
import { calculateBmi, determineBmiDescription, determineBmiRange } from '@src/utils/measure';

import { StyledMetricsForm } from './styles';

const b = bemClassName('metrics-form');

const MetricsForm = ({ onSubmit, onBack }) => {
  const [form] = Form.useForm();
  const [bmi, setBmi] = useState({
    index: 0,
    range: 'Unknown',
    description: '',
  });

  const userData = useSelector(selectOnboardingUserData);

  const updateBmi = useCallback(() => {
    const height = form.getFieldValue('height');
    const weight = form.getFieldValue('weight');

    const bmiValue = calculateBmi(weight, height);
    const bmiRange = determineBmiRange(bmiValue);
    const bmiDescription = determineBmiDescription(bmiValue);

    setBmi({
      index: bmiValue,
      range: bmiRange,
      description: bmiDescription,
    });
  }, [form]);

  const onMeasurementChange = debounce(updateBmi, 300);

  return (
    <StyledMetricsForm>
      <Form className={b()} form={form} layout="vertical" onFinish={onSubmit} initialValues={userData}>
        <div className={b('measurements')}>
          <MeasurementPicker
            name="height"
            measurementType="height"
            orientation="horizontal"
            form={form}
            onMeasurementChange={onMeasurementChange}
          />
          <MeasurementPicker
            name="weight"
            measurementType="weight"
            orientation="horizontal"
            form={form}
            onMeasurementChange={onMeasurementChange}
          />
        </div>

        <div className={b('item')}>
          <BmiGauge bmi={bmi.index.toFixed(1)} />
          <Heading11>{bmi.range}</Heading11>
        </div>

        <div className={b('bmi-description')}>
          <Paragraph1>{bmi.description}</Paragraph1>
        </div>

        <div className={b('submit')}>
          <Form.Item>
            <GradientButton variant={'secondary'} onClick={onBack}>
              BACK
            </GradientButton>
          </Form.Item>
          <Form.Item>
            <GradientButton type="submit">NEXT</GradientButton>
          </Form.Item>
        </div>
      </Form>
    </StyledMetricsForm>
  );
};

export default MetricsForm;
