import styled from 'styled-components';

export const StyledBlogPage = styled.div`
  width: 100%;

  .blog-page {
    width: 100%;
    max-width: 1400px;
    gap: 24px;
    padding: 24px;
    display: flex;
    margin: 60px auto;

    &__left {
      width: calc(100% - 384px);
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    &__right {
      width: 384px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  @media (max-width: 850px) {
    .blog-page {
      gap: 16px;
      flex-wrap: wrap;
      flex-flow: column-reverse;

      &__left {
        width: 100%;
        gap: 16px;
      }

      &__right {
        width: 100%;
        gap: 16px;
      }
    }
  }
`;
