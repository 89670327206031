import React, { useCallback } from 'react';

import { Form } from 'antd';
import { useSelector } from 'react-redux';

import { OptionPicker } from '@src/components/atoms/form/option-picker';
import { GradientButton } from '@src/components/atoms/gradient-button';
import { selectOnboardingUserData } from '@src/store/slices';
import { bemClassName } from '@src/utils/bem';
import { HEALTH_AND_FITNESS_GOALS } from '@src/utils/constants';

import { StyledHealthFitnessGoalsForm } from './styles';

const b = bemClassName('health-fitness-goals-form');

const HealthFitnessGoalsForm = ({ onSubmit, onBack }) => {
  const [form] = Form.useForm();
  const onboardingUserData = useSelector(selectOnboardingUserData);

  const onFinish = useCallback(
    (values) => {
      onSubmit(values);
    },
    [onSubmit],
  );

  return (
    <StyledHealthFitnessGoalsForm>
      <Form
        className={b()}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        defaultValue={onboardingUserData}
        initialValues={onboardingUserData}
      >
        <div className={b('item')}>
          <OptionPicker
            label="Primary Goal"
            name="primaryGoal"
            form={form}
            options={HEALTH_AND_FITNESS_GOALS.primaryGoal}
            required
          />
        </div>

        <div className={b('item')}>
          <OptionPicker
            label="Fitness Preferences"
            name="fitnessPreference"
            form={form}
            options={HEALTH_AND_FITNESS_GOALS.fitnessPreferences}
            multiple
            required
          />
        </div>

        <div className={b('item')}>
          <OptionPicker
            label="Dietary Preferences"
            name="dietaryPreference"
            form={form}
            options={HEALTH_AND_FITNESS_GOALS.dietaryPreferences}
            multiple
            required
          />
        </div>

        <div className={b('submit')}>
          <Form.Item>
            <GradientButton variant={'secondary'} onClick={onBack}>
              BACK
            </GradientButton>
          </Form.Item>
          <Form.Item>
            <GradientButton type="submit">NEXT</GradientButton>
          </Form.Item>
        </div>
      </Form>
    </StyledHealthFitnessGoalsForm>
  );
};

export default HealthFitnessGoalsForm;
