import React, { useCallback, useRef, useState } from 'react';

import { Form, Input } from 'antd';
import { FaEye, FaEyeSlash, FaWindowClose } from 'react-icons/fa';

import { Label1 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledFormInput } from './styles';

const b = bemClassName('form-input');

const FormInput = ({ name, icon, label = 'Form input', rules = [], isPassword, form }) => {
  const [filled, setFilled] = useState(form.getFieldValue(name) && form.getFieldValue(name) !== '');
  const [type, setType] = useState(isPassword ? 'password' : 'text');
  const [showSuffix, setShowSuffix] = useState(false);
  const [inputValue, setInputValue] = useState(form.getFieldValue(name) || '');
  const inputRef = useRef(null);

  const handleFocus = useCallback(() => {
    setFilled(true);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleInputBlur = useCallback((ev) => {
    const fieldValue = ev.target.value.trim();

    if (fieldValue === '') {
      setFilled(false);
    }
  }, []);

  const handleChange = useCallback((ev) => {
    const fieldValue = ev.target.value;
    setInputValue(fieldValue);
    setShowSuffix(fieldValue.trim() !== '');
  }, []);

  const handleType = useCallback(() => {
    setType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  }, []);

  const handleClear = useCallback(() => {
    form.setFieldsValue({ [name]: '' });

    setInputValue('');
    setShowSuffix(false);

    if (isPassword) {
      setType('password');
    }
  }, [form, name, isPassword]);

  return (
    <StyledFormInput>
      <Form.Item name={name} rules={rules} onClick={handleFocus} onFocus={handleFocus} onBlur={handleInputBlur}>
        <span className={b({ filled })}>
          <Input
            ref={inputRef}
            id={name}
            type={type}
            value={inputValue}
            suffix={
              <>
                {isPassword && type === 'password' && (
                  <FaEyeSlash className={b('type', { showSuffix })} onClick={handleType} />
                )}
                {isPassword && type === 'text' && <FaEye className={b('type', { showSuffix })} onClick={handleType} />}
                <FaWindowClose className={b('clear', { showSuffix })} onClick={handleClear} />
              </>
            }
            onBlur={handleInputBlur}
            onChange={handleChange}
          />
          <Label1 className={b('label')} htmlFor={name}>
            <span className={b('label', 'content')}>
              {icon && <div className={b('label', 'content', 'icon')}>{icon}</div>}
              {label}
            </span>
          </Label1>
        </span>
      </Form.Item>
    </StyledFormInput>
  );
};

export default FormInput;
