import React, { useCallback, useMemo } from 'react';

import { Card } from 'antd';

import { GradientButton } from '@src/components/atoms/gradient-button';
import { TitleBadge } from '@src/components/atoms/title-badge';
import { Swiper } from '@src/components/organisms/swiper';
import { Heading2, Heading7, Paragraph3 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { services } from './ServicesData';
import { StyledAboutOurServicesSection } from './styles';

const b = bemClassName('about-our-services-section');

const AboutOurServicesSection = () => {
  const handleServiceCardClick = useCallback(() => {
    // TODO: Handle service card click
  }, []);

  const slides = useMemo(
    () =>
      services.map((data) => ({
        content: (
          <Card hoverable cover={data.icon}>
            <Heading2>{data.title}</Heading2>
            <Paragraph3>{data.paragraph}</Paragraph3>
            <GradientButton onClick={handleServiceCardClick}>Read More</GradientButton>
          </Card>
        ),
      })),
    [handleServiceCardClick],
  );

  return (
    <StyledAboutOurServicesSection>
      <div className={b()}>
        <div className={b('inner')}>
          <div className={b('inner', 'heading')}>
            <TitleBadge text="OUR SERVICES" />
            <Heading7>Service We Provide</Heading7>
          </div>
          <div className={b('inner', 'swiper')}>
            <Swiper slides={slides} />
          </div>
        </div>
      </div>
    </StyledAboutOurServicesSection>
  );
};

export default AboutOurServicesSection;
