import styled from 'styled-components';

export const StyledRecentPosts = styled.div`
  position: relative;
  padding: 32px;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.athensGray};

  .recent-posts {
    &__title {
      margin-bottom: 24px;
    }

    &__item {
      margin-bottom: 20px;
    }
  }
`;
