import React, { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { GradientButton } from '@src/components/atoms/gradient-button';
import { HorizontalMenu } from '@src/components/molecules/horizontal-menu';
import SingleLogo from '@src/theme/assets/logo/logo-vertical-v3.png';
import { bemClassName } from '@src/utils/bem';

import { StyledLandingMenu } from './styles';
import { LoginModal } from '../login-modal';

const b = bemClassName('landing-menu');

const LandingMenu = () => {
  const navigate = useNavigate();
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);

  const goToOnboarding = useCallback(() => {
    navigate('/onboarding');
  }, [navigate]);

  const openLoginModal = useCallback(() => {
    setIsOpenLoginModal(true);
  }, []);

  const closeLoginModal = useCallback(() => {
    setIsOpenLoginModal(false);
  }, []);

  return (
    <StyledLandingMenu>
      <div className={b()}>
        <div className={b('left')}>
          <HorizontalMenu
            items={[
              { label: 'Home', link: '/' },
              { label: 'Pricing', link: '/pricing' },
              { label: 'About', link: '/about' },
            ]}
          />
        </div>
        <div className={b('logo')}>
          <img src={SingleLogo} />
          <svg viewBox="0 0 100 100">
            <polygon
              points="-4,0 104,0 130,100 -30,100"
              fill="white"
              stroke="black"
              strokeWidth="0.5"
              shapeRendering="geometricPrecision"
            />
          </svg>
        </div>
        <div className={b('right')}>
          <HorizontalMenu
            items={[
              {
                content: <GradientButton onClick={openLoginModal}>SIGN IN</GradientButton>,
              },
              {
                content: <GradientButton onClick={goToOnboarding}>SIGN UP</GradientButton>,
              },
            ]}
          />
        </div>
      </div>
      <LoginModal isOpen={isOpenLoginModal} onOk={closeLoginModal} onCancel={closeLoginModal} />
    </StyledLandingMenu>
  );
};

export default LandingMenu;
