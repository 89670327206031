import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getStringFromLocalStorage } from '@src/utils/local-storage';

export const authApi = createApi({
  reducerPath: 'authQueries',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    prepareHeaders: (headers) => {
      const token = getStringFromLocalStorage({ key: 'accessToken' });

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    signUp: builder.mutation({
      query: (body) => ({
        url: '/auth/sign-up',
        method: 'POST',
        body,
      }),
    }),
    sendCode: builder.mutation({
      query: (phoneNumber) => ({
        url: '/auth/send-code',
        method: 'POST',
        body: { phoneNumber },
      }),
    }),
    verifyCode: builder.mutation({
      query: ({ phoneNumber, code }) => ({
        url: '/auth/verify-code',
        method: 'POST',
        body: { phoneNumber, code },
      }),
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: '/auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    refreshToken: builder.mutation({
      query: (refreshToken) => ({
        url: '/auth/refresh-token',
        method: 'POST',
        body: { refreshToken },
      }),
    }),
  }),
});

export const {
  useSignUpMutation,
  useSendCodeMutation,
  useVerifyCodeMutation,
  useLoginMutation,
  useRefreshTokenMutation,
} = authApi;
