import React from 'react';

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import { RoundedIconAnimated } from '@src/components/atoms/rounded-icon-animated';
import { bemClassName } from '@src/utils/bem';

import { StyledSimpleRoundedPaginator } from './styles';

const b = bemClassName('simple-rounded-paginator');

const SimpleRoundedPaginator = ({ pages = 0, activePage = 1, backgroundColor }) => {
  return (
    <StyledSimpleRoundedPaginator>
      <div className={b()}>
        {pages > 1 && (
          <RoundedIconAnimated backgroundColor={backgroundColor}>
            <FaArrowLeft />
          </RoundedIconAnimated>
        )}
        {pages &&
          Array.from({ length: pages }).map((_, index) => (
            <div key={`paginator-item-${index + 1}`} className={b('item')}>
              <RoundedIconAnimated active={activePage == index + 1} backgroundColor={backgroundColor}>
                {index + 1}
              </RoundedIconAnimated>
            </div>
          ))}
        {pages > 1 && (
          <RoundedIconAnimated backgroundColor={backgroundColor}>
            <FaArrowRight />
          </RoundedIconAnimated>
        )}
      </div>
    </StyledSimpleRoundedPaginator>
  );
};

export default SimpleRoundedPaginator;
