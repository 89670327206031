import React from 'react';

import { HoverScaleImage } from '@src/components/atoms/hover-scale-image';
import { Heading15 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledProjectGallery } from './styles';

const b = bemClassName('project-gallery');

const ProjectGallery = ({ title, sources }) => {
  return (
    <StyledProjectGallery>
      <div className={b()}>
        <div className={b('title')}>
          <Heading15>{title}</Heading15>
        </div>
        <div className={b('content')}>
          {sources.map((source, index) => (
            <div key={`project-gallery-item-${index}`} className={b('content', 'item')}>
              <HoverScaleImage source={source} width="95px" height="95px" />
            </div>
          ))}
        </div>
      </div>
    </StyledProjectGallery>
  );
};

export default ProjectGallery;
