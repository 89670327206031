import styled from 'styled-components';

import MobileMainHeaderBg from '@src/theme/assets/backgrounds/bg-2-irregular.png';

export const StyledLoginModal = styled.div`
  .login-modal {
    &__body {
      display: flex;

      &__left-column,
      &__right-column {
        display: flex;
      }

      &__left-column {
        width: 54%;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${({ theme }) => theme.colors.iron};

        &__animation {
          position: absolute;
          width: 200px;
          top: calc(50% - 180px);
          left: calc(50% - 180px);
        }

        &__title {
          transform: translate(40px, 50px);
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: url(${MobileMainHeaderBg});
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        &::before {
          transform: rotate(45deg) translate(-18%, -68%);
        }

        &::after {
          transform: rotate(-135deg) translate(-18%, -68%);
        }
      }

      &__right-column {
        width: 46%;
        display: flex;
        flex-direction: column;
        padding: 64px 32px 232px 32px;
        gap: 10px;
        text-align: center;
        background-color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;
