import styled from 'styled-components';

export const StyledHoverScaleImage = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 20px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    overflow: clip;
    transition: 0.4s ease-in-out;

    &:hover {
      transform: scale(1.03) translateZ(0);
    }
  }
`;
