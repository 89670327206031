import styled from 'styled-components';

export const StyledBlogCard = styled.div`
  width: 100%;

  .blog-card {
    position: relative;
    display: flex;
    background: ${({ theme }) => theme.colors.athensGray};
    width: 100%;
    border-radius: 20px;
    padding: 16px 60px 16px 16px;
    gap: 30px;

    &__image {
      width: 300px;
    }

    &__content {
      &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin: 12px 0 18px 0;

        &__item {
          width: fit-content;
        }
      }

      &__title {
        margin-bottom: 22px;
      }
    }

    &:hover {
      img {
        transform: scale(1.03) translateZ(0);
      }
    }
  }

  @media (max-width: 1250px) {
    .blog-card {
      flex-wrap: wrap;
    }
  }

  @media (max-width: 1200px) {
    .blog-card {
      padding: 16px 16px 24px 16px;
      gap: 20px;
      justify-content: center;
      align-items: center;

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        text-align: center;

        &__tags {
          justify-content: center;
        }
      }
    }
  }
`;
