import styled from 'styled-components';

export const StyledRoundedIconAnimated = styled.div`
  height: 45px;
  width: 45px;
  color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.black)};
  background: ${({ theme, active, backgroundColor }) =>
    active ? theme.colors.mainOrange : backgroundColor ?? theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.mainOrange};
  }
`;
