import React, { useCallback, useEffect, useState } from 'react';

import { Button, Form } from 'antd';
import { FaMinus, FaPlus } from 'react-icons/fa';

import { Heading2 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledRangePicker } from './styles';

const b = bemClassName('range-picker');

const RangePicker = ({ orientation = 'horizontal', labelPosition = 'top', name, form, steps = [] }) => {
  const stepPerCent = steps.length > 1 ? 100 / (steps.length - 1) : 0;
  const step = Math.floor(stepPerCent);
  const max = step * (steps.length - 1);
  const initialValue = form.getFieldValue(name) ?? 0;

  const [value, setValue] = useState(initialValue);
  const [label, setLabel] = useState(steps[0]);

  useEffect(() => {
    if (step > 0) {
      const index = Math.floor(value / step);
      setLabel(steps[index]);
    } else {
      setLabel('The steps, must have at least 2 elements');
    }
  }, [step, steps, value]);

  const onChange = useCallback((e) => {
    setValue(e.target.value * 1);
  }, []);

  const increaseValue = useCallback(() => {
    setValue(Math.min(value + step, max));
  }, [max, step, value]);

  const decreaseValue = useCallback(() => {
    setValue(Math.max(value - step, 0));
  }, [step, value]);

  return (
    <StyledRangePicker orientation={orientation} labelPosition={labelPosition} step={stepPerCent}>
      <Form.Item name={name}>
        <div className={b()}>
          {step > 0 && (
            <div className={b('control')}>
              <Button
                icon={orientation === 'vertical' ? <FaPlus /> : <FaMinus />}
                shape="circle"
                onClick={orientation === 'vertical' ? increaseValue : decreaseValue}
              />
              <div className={b('control', 'wrapper')}>
                <input type="range" min={0} max={max} value={value} step={step} onChange={onChange} />
              </div>
              <Button
                icon={orientation === 'vertical' ? <FaMinus /> : <FaPlus />}
                shape="circle"
                onClick={orientation === 'vertical' ? decreaseValue : increaseValue}
              />
            </div>
          )}

          {!!label && (
            <div className={b('label')}>
              <Heading2>{label}</Heading2>
            </div>
          )}
        </div>
      </Form.Item>
    </StyledRangePicker>
  );
};

export default RangePicker;
