import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { MainLayout } from '@src/layouts/main';
import { OnboardingLayout } from '@src/layouts/onboarding';
import { AboutPage } from '@src/pages/about';
import { BlogPage } from '@src/pages/blog';
import { HomePage } from '@src/pages/home';
import { LandingPage } from '@src/pages/landing';
import { OnboardingPage } from '@src/pages/onboarding';
import { PricingPage } from '@src/pages/pricing';

import '@src/theme/styles/style.scss';
import '@src/theme/styles/antd-overrides.scss';

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/" element={<MainLayout />}>
        <Route path="home" element={<HomePage />} />
        <Route path="pricing" element={<PricingPage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="blog" element={<BlogPage />} />
      </Route>
      <Route path="/" element={<OnboardingLayout />}>
        <Route path="onboarding" element={<OnboardingPage />} />
      </Route>
    </Routes>
  );
};

export default MainRoutes;
