import React from 'react';

import block from 'bem-cn';
import { FaArrowRight } from 'react-icons/fa';

import { SearchForm } from '@src/components/molecules/search-form';
import { SimpleRoundedPaginator } from '@src/components/molecules/simple-rounded-paginator';
import { TagsCloud } from '@src/components/molecules/tags-cloud';
import { BlogCard } from '@src/components/organisms/blog-card';
import { BlogCategoriesList } from '@src/components/organisms/blog-categories-list';
import { ProjectGallery } from '@src/components/organisms/project-gallery';
import { RecentPosts } from '@src/components/organisms/recent-posts';
import { variables } from '@src/theme';
import image from '@src/theme/assets/icecream-1.webp';

import { StyledBlogPage } from './styles';

const b = block('blog-page');

const blogData = [
  {
    id: 1,
    image: image,
    tags: ['JUNE 15, 2023', 'Shopping'],
    text: "Mastering the Machines: A Beginner's Guide to Gym Equipment",
    textLink: '/blog',
    iconTextLink: '/blog',
  },
  {
    id: 2,
    image: image,
    tags: ['JUNE 15, 2023', 'Shopping'],
    text: 'Mastering the Gym: Essential Strategies for Maximizing Your Workouts',
    textLink: '/blog',
    iconTextLink: '/blog',
  },
  {
    id: 3,
    image: image,
    tags: ['JUNE 15, 2023', 'Shopping'],
    text: 'Breaking the Sweat Barrier: Embrace the Challenge of Fitness at Fitmas',
    textLink: '/blog',
    iconTextLink: '/blog',
  },
  {
    id: 4,
    image: image,
    tags: ['JUNE 15, 2023', 'Shopping'],
    text: "Mastering the Machines: A Beginner's Guide to Gym Equipment",
    textLink: '/blog',
    iconTextLink: '/blog',
  },
  {
    id: 5,
    image: image,
    tags: ['JUNE 15, 2023', 'Shopping'],
    text: 'Mastering the Gym: Essential Strategies for Maximizing Your Workouts',
    textLink: '/blog',
    iconTextLink: '/blog',
  },
  {
    id: 6,
    image: image,
    tags: ['JUNE 15, 2023', 'Shopping'],
    text: 'Breaking the Sweat Barrier: Embrace the Challenge of Fitness at Fitmas',
    textLink: '/blog',
    iconTextLink: '/blog',
  },
];

const recentPostData = [
  {
    id: 1,
    image: image,
    text: 'Essential Strategies For Maximizing Your',
    textLink: '/blog',
    date: '25 JUN, 2023',
  },
  {
    id: 2,
    image: image,
    text: 'Functional Training Into Your Routine...',
    textLink: '/blog',
    date: '25 JUN, 2023',
  },
  {
    id: 3,
    image: image,
    text: 'Nutrition Tips Advice For Gym Goers',
    textLink: '/blog',
    date: '25 JUN, 2023',
  },
];

const categoriesData = [
  {
    id: 1,
    text: 'Boxing Training',
    textLink: '/blog',
  },
  {
    id: 2,
    text: 'Body Building Class',
    textLink: '/blog',
  },
  {
    id: 3,
    text: 'Meditation Class',
    textLink: '/blog',
  },
  {
    id: 4,
    text: 'Mental Yoga Training',
    textLink: '/blog',
  },
  {
    id: 5,
    text: 'CrossFit Body Class',
    textLink: '/blog',
  },
];

const galleryData = [image, image, image, image, image, image];

const tagsData = [
  'ADVICE',
  'FIT',
  'AUTHOR',
  'FAMILY',
  'FITMAS',
  'HEALTH',
  'FITNESS',
  'GYM',
  'JUDGE',
  'SOLUTION',
  'TRAINING',
];

const BlogPage = () => {
  return (
    <StyledBlogPage>
      <div className={b()}>
        <div className={b('left')}>
          {blogData.map((blog) => (
            <div key={`blog-card-item-${blog.id}`} className={b('content', 'left', 'item')}>
              <BlogCard
                image={blog.image}
                tags={blog.tags}
                text={blog.text}
                textLink={blog.textLink}
                icon={<FaArrowRight />}
                iconText={'Read More'}
                iconTextLink={blog.iconTextLink}
              />
            </div>
          ))}

          <div className={b('left', 'pagination')}>
            <SimpleRoundedPaginator pages={4} backgroundColor={variables.colors.athensGray} />
          </div>
        </div>
        <div className={b('right')}>
          <SearchForm />
          <RecentPosts title={'Recent Posts'} posts={recentPostData} />
          <BlogCategoriesList title={'Categories'} categories={categoriesData} />
          <ProjectGallery title={'Project Gallery'} sources={galleryData} />
          <TagsCloud title={'Popular Tags'} tags={tagsData} />
        </div>
      </div>
    </StyledBlogPage>
  );
};

export default BlogPage;
