import React from 'react';

import { Form } from 'antd';

import { FormInput } from '@src/components/atoms/form/form-input';
import { GradientButton } from '@src/components/atoms/gradient-button';
import { TitleUnderline } from '@src/components/atoms/title-underline';
import { Paragraph1 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledFooterNewsletter } from './styles';

const b = bemClassName('newsletter');

const FooterNewsletter = () => {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    // TODO: Handle submit
  };

  return (
    <StyledFooterNewsletter>
      <div className={b()}>
        <div className={b('heading')}>
          <TitleUnderline text="Get Newsletter" />
          <Paragraph1>Stay tuned with the latest trends and news</Paragraph1>
        </div>
        <div className={b('form')}>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <FormInput
              name="formEmail"
              label="Email Address"
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'The input is not a valid E-mail!' },
              ]}
              form={form}
            />
            <Form.Item>
              <GradientButton htmlType="submit">SUBSCRIBE</GradientButton>
            </Form.Item>
          </Form>
        </div>
      </div>
    </StyledFooterNewsletter>
  );
};

export default FooterNewsletter;
