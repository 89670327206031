import React from 'react';

import { PostItem } from '@src/components/organisms/post-item';
import { Heading15 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledRecentPosts } from './styles';

const b = bemClassName('recent-posts');

const RecentPosts = ({ title, posts }) => {
  return (
    <StyledRecentPosts>
      <div className={b()}>
        <div className={b('title')}>
          <Heading15>{title}</Heading15>
        </div>
        {posts.map((post) => (
          <div key={`recent-post-item-${post.id}`} className={b('item')}>
            <PostItem image={post.image} text={post.text} textLink={post.textLink} date={post.date} />
          </div>
        ))}
      </div>
    </StyledRecentPosts>
  );
};

export default RecentPosts;
