import React from 'react';

import { TitleBadge } from '@src/components/atoms/title-badge';
import { bemClassName } from '@src/utils/bem';

import { StyledAboutOurProductsSection } from './styles';

const b = bemClassName('about-our-products-section');

const AboutOurProductsSection = () => {
  // const handleProductClick = useCallback(() => {
  //
  // }, []);

  return (
    <StyledAboutOurProductsSection>
      <div className={b()}>
        <div className={b('inner')}>
          <div className={b('inner', 'heading')}>
            <TitleBadge text="OUR PRODUCTS" />
          </div>
        </div>
      </div>
    </StyledAboutOurProductsSection>
  );
};

export default AboutOurProductsSection;
