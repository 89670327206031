import styled from 'styled-components';

export const StyledLandingMenu = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.concrete};

  .landing-menu {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};

    &__logo {
      width: 244px;
      min-width: 244px;
      height: 152px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-16px);

      img {
        max-width: 84%;
        max-height: 84%;
        cursor: pointer;
      }

      svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
      }
    }

    &__auto,
    &__left,
    &__right {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__left {
      margin-right: 80px;
    }

    &__auto {
      margin-left: auto;
      margin-right: auto;
    }

    &__right {
      margin-left: 80px;
    }
  }

  @media (max-width: 1160px) {
    .landing-menu {
      &__left {
        margin-right: 40px;
      }

      &__right {
        margin-left: 40px;
      }
    }
  }
`;
