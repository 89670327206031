import React from 'react';

import { TextLink } from '@src/components/atoms/text-link';
import { TitleUnderline } from '@src/components/atoms/title-underline';
import { variables } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledFooterMenu } from './styles';

const b = bemClassName('footer-menu');

const FooterMenu = () => {
  return (
    <StyledFooterMenu>
      <div className={b()}>
        <TitleUnderline text="Quick Links" />

        <ul className={b('list')}>
          <li className={b('list', 'item')}>
            <TextLink color={variables.colors.white} to="/">
              Home Page
            </TextLink>
          </li>
          <li className={b('list', 'item')}>
            <TextLink color={variables.colors.white} to="/pricing">
              Pricing
            </TextLink>
          </li>
          <li className={b('list', 'item')}>
            <TextLink color={variables.colors.white} to="/about">
              About Us
            </TextLink>
          </li>
        </ul>
      </div>
    </StyledFooterMenu>
  );
};

export default FooterMenu;
