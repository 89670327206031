import React, { useCallback, useState } from 'react';

import { GradientButton } from '@src/components/atoms/gradient-button';
import { bemClassName } from '@src/utils/bem';

import { StyledAnimatedButtonsCards } from './styles';

const b = bemClassName('animated-buttons-cards');

const AnimatedButtonsCards = ({ items }) => {
  const [activeCard, setActiveCard] = useState(0);
  const [opacity, setOpacity] = useState(1);

  const handleClick = useCallback(
    (index) => {
      if (activeCard !== index) {
        setOpacity(0);

        setTimeout(() => {
          setActiveCard(index);
          setOpacity(1);
        }, 500);
      }
    },
    [activeCard],
  );

  return (
    <StyledAnimatedButtonsCards>
      <div className={b()}>
        <div className={b('buttons')}>
          {items.map((item, index) => (
            <GradientButton key={index} onClick={() => handleClick(index)} active={activeCard === index}>
              {item.buttonText}
            </GradientButton>
          ))}
        </div>
        <div className={b('cards')}>
          <div className={`${b('cards', 'card')}`}>
            <div className={`${b('cards', 'card', 'icon')}`} style={{ opacity: opacity }}>
              {items[activeCard].icon}
            </div>
            <div className={`${b('cards', 'card', 'content')}`} style={{ opacity: opacity }}>
              {items[activeCard].cardContent}
            </div>
          </div>
        </div>
      </div>
    </StyledAnimatedButtonsCards>
  );
};

export default AnimatedButtonsCards;
