import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AnimatedProgressBar } from '@src/components/atoms/animated-progress-bar';
import { GradientButton } from '@src/components/atoms/gradient-button';
import { selectOnboardingCurrentStep, selectOnboardingProgress, selectOnboardingTotalSteps } from '@src/store/slices';
import SingleLogo from '@src/theme/assets/logo/logo-vertical-v3.png';
import { bemClassName } from '@src/utils/bem';

import { StyledOnboardingMenu } from './styles';

const b = bemClassName('onboarding-menu');

const OnboardingMenu = () => {
  const onboardingProgress = useSelector(selectOnboardingProgress);
  const onboardingCurrentStep = useSelector(selectOnboardingCurrentStep);
  const onboardingTotalSteps = useSelector(selectOnboardingTotalSteps);

  const navigate = useNavigate();

  const goToHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <StyledOnboardingMenu>
      <div className={b()}>
        <div className={b('logo-container')}>
          <img src={SingleLogo} />
        </div>
        <div className={b('center-section')}>
          <AnimatedProgressBar
            progress={onboardingProgress}
            currentStep={onboardingCurrentStep}
            totalSteps={onboardingTotalSteps}
          />
        </div>
        <div className={b('right-section')}>
          <GradientButton onClick={goToHome}>HOME</GradientButton>
        </div>
      </div>
    </StyledOnboardingMenu>
  );
};

export default OnboardingMenu;
