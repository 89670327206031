import React from 'react';

import { LandingHeader } from '@src/components/organisms/landing-header';
import { LandingMenu } from '@src/components/organisms/landing-menu';
import { MainFooter } from '@src/components/organisms/main-footer';
import { TopMenu } from '@src/components/organisms/top-menu';
import MobileMainHeaderBg from '@src/theme/assets/backgrounds/bg-2-irregular.png';
import MainHeaderBg from '@src/theme/assets/backgrounds/bg-2.webp';

import { OurServices } from './our-services';
import { Plans } from './plans';
import { StyledLandingPage } from './styles';

const LandingPage = () => {
  return (
    <StyledLandingPage>
      <div className="landing-page">
        <div className="landing-page__mobile-menu">
          <TopMenu />
        </div>
        <LandingHeader backgroundImage={MainHeaderBg} mobileBackgroundImage={MobileMainHeaderBg} />
        <div className="landing-page__desktop-menu">
          <LandingMenu />
        </div>
        <div className="landing-page__container">
          <OurServices />
          <Plans />
        </div>
        <MainFooter />
      </div>
    </StyledLandingPage>
  );
};

export default LandingPage;
