import React from 'react';

import { block } from 'bem-cn';

import { LottiePlayer } from '@src/components/atoms/lottie-player';
import { Modal } from '@src/components/atoms/modal';
import { Heading2, Heading4 } from '@src/theme';
import { login } from '@src/theme/assets/animations';
import { lottieDefaultOptions } from '@src/utils/settings';

import { LoginForm } from './login-form';
import { StyledLoginModal } from './styles';

const b = block('login-modal');

const LoginModal = ({ isOpen, onOk, onCancel }) => {
  return (
    <Modal
      className="login-modal-container p-0"
      isOpen={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      width="90%"
    >
      <StyledLoginModal>
        <div className={b()}>
          <div className={b('body')}>
            <div className={b('body', 'left-column')}>
              <div className={b('body', 'left-column', 'title')}>
                <Heading4>Welcome Back</Heading4>
              </div>
              <div className={b('body', 'left-column', 'animation')}>
                <LottiePlayer animationData={login} options={lottieDefaultOptions} />
              </div>
            </div>
            <div className={b('body', 'right-column')}>
              <Heading2>Sign In</Heading2>
              <LoginForm />
            </div>
          </div>
        </div>
      </StyledLoginModal>
    </Modal>
  );
};

export default LoginModal;
