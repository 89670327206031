import React from 'react';

import { StyledRoundedIconAnimated } from './styles';

const RoundedIconAnimated = ({ backgroundColor, active = false, children }) => {
  return (
    <StyledRoundedIconAnimated backgroundColor={backgroundColor} active={active}>
      {children}
    </StyledRoundedIconAnimated>
  );
};

export default RoundedIconAnimated;
