import React, { useCallback } from 'react';

import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { ImWhatsapp } from 'react-icons/im';
import { IoMailOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import { GradientButton } from '@src/components/atoms/gradient-button';
import { HorizontalMenu } from '@src/components/molecules/horizontal-menu';
import { Paragraph1, TextLink1, TextLink2 } from '@src/theme';
import SingleLogo from '@src/theme/assets/logo/logo-vertical-v3.png';
import { bemClassName } from '@src/utils/bem';

import { StyledMainMenu } from './styles';

const b = bemClassName('main-menu');

const MainMenu = () => {
  const navigate = useNavigate();

  const goToOnboarding = useCallback(() => {
    navigate('/onboarding');
  }, [navigate]);

  return (
    <StyledMainMenu>
      <div className={b()}>
        <div className={b('logo-container')}>
          <img src={SingleLogo} />
        </div>
        <div className={b('center-section')}>
          <div className={b('center-section', 'top')}>
            <div className={b('center-section', 'top', 'contact')}>
              <TextLink1>
                <IoMailOutline />
                torres.raidel@gmail.com
              </TextLink1>
              <TextLink1>
                <ImWhatsapp />
                (593) 999999999
              </TextLink1>
            </div>
            <div className={b('center-section', 'top', 'social')}>
              <Paragraph1>Visit our social pages:</Paragraph1>
              <div className={b('center-section', 'top', 'social', 'links')}>
                <TextLink2>
                  <FaFacebook />
                </TextLink2>
                <TextLink2>
                  <FaTwitter />
                </TextLink2>
                <TextLink2>
                  <FaLinkedin />
                </TextLink2>
                <TextLink2>
                  <FaInstagram />
                </TextLink2>
              </div>
            </div>
          </div>
          <div className={b('center-section', 'bottom')}>
            <div className={b('right')}>
              <HorizontalMenu
                items={[
                  { label: 'Home', link: '/' },
                  { label: 'Pricing', link: '/pricing' },
                  { label: 'About', link: '/about' },
                  { label: 'Blog', link: '/blog' },
                ]}
              />
            </div>
          </div>
        </div>
        <div className={b('right-section')}>
          <GradientButton onClick={goToOnboarding}>SIGN UP</GradientButton>
        </div>
      </div>
    </StyledMainMenu>
  );
};

export default MainMenu;
