import styled from 'styled-components';

export const StyledProjectGallery = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.athensGray};
  padding: 26px;
  border-radius: 20px;

  .project-gallery {
    &__title {
      margin-bottom: 24px;
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;

      &__item {
        width: fit-content;
      }
    }
  }
`;
