import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getStringFromLocalStorage } from '@src/utils/local-storage';

export const usersApi = createApi({
  reducerPath: 'usersQueries',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    prepareHeaders: (headers) => {
      const token = getStringFromLocalStorage({ key: 'accessToken' });

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => 'users/profile',
    }),
    upsertUser: builder.mutation({
      query: ({ userId, upsertUserDto }) => ({
        url: `users/upsert/${userId}`,
        method: 'PUT',
        body: upsertUserDto,
      }),
    }),
  }),
});

export const { useGetProfileQuery, useUpsertUserMutation } = usersApi;
