export const HEALTH_AND_FITNESS_GOALS = {
  primaryGoal: [
    { value: 'pg1', text: 'Gain Muscle' },
    { value: 'pg2', text: 'Maintain Weight' },
    { value: 'pg3', text: 'Lose Weight' },
    { value: 'pg4', text: 'Improve Performance' },
    { value: 'pg5', text: 'Others' },
  ],
  dietaryPreferences: [
    { value: 'dp1', text: 'Intermittent Fasting' },
    { value: 'dp2', text: 'Paleo' },
    { value: 'dp3', text: 'Keto' },
    { value: 'dp4', text: 'Vegetarian' },
    { value: 'dp5', text: 'Vegan' },
    { value: 'dp6', text: 'Mediterranean' },
    { value: 'dp7', text: 'Others' },
  ],
  fitnessPreferences: [
    { value: 'fp1', text: 'Cardio Exercises' },
    { value: 'fp2', text: 'Strength Training' },
    { value: 'fp3', text: 'Yoga / Pilates' },
    { value: 'fp4', text: 'Running / Cycling' },
    { value: 'fp5', text: 'Swimming' },
    { value: 'fp6', text: 'Outdoor Activities' },
    { value: 'fp7', text: 'Others' },
  ],
};

export const HEALTH_INFORMATION = {
  medicalConditions: [
    { value: 'mc01', text: 'Diabetes' },
    { value: 'mc03', text: 'Heart disease' },
    { value: 'mc04', text: 'Asthma' },
    { value: 'mc05', text: 'Arthritis' },
    { value: 'mc06', text: 'Thyroid disorders' },
    { value: 'mc09', text: 'Kidney disease' },
    { value: 'mc10', text: 'Celiac disease' },
  ],
  allergies: [
    { value: 'all01', text: 'Peanuts' },
    { value: 'all02', text: 'Tree nuts' },
    { value: 'all03', text: 'Dairy' },
    { value: 'all04', text: 'Gluten' },
    { value: 'all05', text: 'Shellfish' },
    { value: 'all06', text: 'Eggs' },
    { value: 'all07', text: 'Soy' },
    { value: 'all08', text: 'Fish' },
    { value: 'all09', text: 'Sesame' },
  ],
};
