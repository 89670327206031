import React, { useCallback } from 'react';

import { Form } from 'antd';
import { FaEnvelope, FaShieldAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { FormInput } from '@src/components/atoms/form/form-input';
import { GradientButton } from '@src/components/atoms/gradient-button';
import { selectOnboardingUserData } from '@src/store/slices';
import { bemClassName } from '@src/utils/bem';

import { StyledPersonalInformationForm } from './styles';

const b = bemClassName('personal-information-form');

const PersonalInformationForm = ({ onSubmit }) => {
  const [form] = Form.useForm();
  const onboardingUserData = useSelector(selectOnboardingUserData);

  const onFinish = useCallback(
    (values) => {
      onSubmit(values);
    },
    [onSubmit],
  );

  return (
    <StyledPersonalInformationForm>
      <Form
        className={b()}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        defaultValue={onboardingUserData}
        initialValues={onboardingUserData}
      >
        <div className={b('item')}>
          <FormInput
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please input your name!' }]}
            form={form}
          />
        </div>
        <div className={b('item')}>
          <FormInput
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'The input is not valid E-mail!' },
            ]}
            icon={<FaEnvelope />}
            form={form}
          />
        </div>
        <div className={b('item')}>
          <FormInput
            name="password"
            label="Password"
            rules={[
              { required: true, message: 'Please input your password!' },
              { min: 6, message: 'Password must be at least 6 characters long!' },
            ]}
            icon={<FaShieldAlt />}
            isPassword
            form={form}
          />
        </div>
        <div className={b('item')}>
          <FormInput
            name="confirmPassword"
            label="Confirm Password"
            rules={[
              { required: true, message: 'Please retype your password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
            icon={<FaShieldAlt />}
            isPassword
            form={form}
          />
        </div>
        <div className={b('submit')}>
          <Form.Item>
            <GradientButton type="submit">NEXT</GradientButton>
          </Form.Item>
        </div>
      </Form>
    </StyledPersonalInformationForm>
  );
};

export default PersonalInformationForm;
