import React from 'react';

import { HoverScaleImage } from '@src/components/atoms/hover-scale-image';
import { TextLinkColor } from '@src/components/atoms/text-link-color';
import { Paragraph7, Paragraph8 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledPostItem } from './styles';

const b = bemClassName('post-item');

const PostItem = ({ image, text, textLink, date }) => {
  return (
    <StyledPostItem>
      <div className={b()}>
        <div className={b('image')}>
          <HoverScaleImage source={image} width="80px" height="80px" />
        </div>
        <div className={b('content')}>
          <div className={b('content', 'title')}>
            <Paragraph7>
              <TextLinkColor to={textLink}>{text}</TextLinkColor>
            </Paragraph7>
          </div>

          <div className={b('content', 'date')}>
            <Paragraph8>
              <TextLinkColor>{date}</TextLinkColor>
            </Paragraph8>
          </div>
        </div>
      </div>
    </StyledPostItem>
  );
};

export default PostItem;
