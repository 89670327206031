import styled from 'styled-components';

export const StyledPostItem = styled.div`
  width: 100%;

  .post-item {
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.colors.transparent};
    border-radius: 6px;
    gap: 20px;
    transition: all 0.4s;

    &__image {
      width: 80px;
    }

    &__content {
      &__title {
      }

      &__date {
      }
    }

    &:hover img {
      transform: scale(1.03) translateZ(0);
    }
  }
`;
