import React from 'react';

import { bemClassName } from '@src/utils/bem';

import { StyledGradientButton } from './styles';

const b = bemClassName('gradient-button');

const GradientButton = ({ children, variant, active, onClick, type = 'button' }) => {
  return (
    <StyledGradientButton>
      <button className={b({ variant, active })} onClick={onClick} type={type}>
        {children}
      </button>
    </StyledGradientButton>
  );
};

export default GradientButton;
