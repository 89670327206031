import React from 'react';

import { Table } from 'antd';
import { block } from 'bem-cn';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { GradientButton } from '@src/components/atoms/gradient-button';
import { Heading1, Heading2, Label2 } from '@src/theme';

import { StyledPlansComparisonTable } from './styles';

const b = block('plans-comparison-table');

const PlansComparisonTable = ({ pricingData }) => {
  const columns = [
    {
      title: '',
      dataIndex: 'feature',
      key: 'feature',
      render: (text, record) => {
        if (record.key === 'buttons') return '';
        return <Label2 className={b('feature-description')}>{text}</Label2>;
      },
    },
    ...pricingData.map((plan) => ({
      title: <Heading2>{plan.title}</Heading2>,
      dataIndex: plan.id,
      key: plan.id,
      render: (value, record) => {
        if (record.key === 'buttons') return <GradientButton>Choose this plan</GradientButton>;
        return typeof value === 'boolean' ? value ? <FaCheck color="green" /> : <FaTimes color="gray" /> : value;
      },
    })),
  ];

  const dataSource = [
    ...[
      'Recipes section',
      'Workout routines',
      'Tracking tools',
      'Reporting and insights',
      'Monthly health reports in your inbox',
      'Swimming workouts',
    ].map((feature, index) => ({
      key: `feature-${index}`,
      feature,
      ...pricingData.reduce((acc, plan) => {
        acc[plan.id] = plan.features.includes(feature);
        return acc;
      }, {}),
    })),
    {
      key: 'monthlyPrice',
      feature: 'Monthly Price',
      ...pricingData.reduce((acc, plan) => {
        acc[plan.id] = `$${plan.monthlyPrice.toFixed(2)}`;
        return acc;
      }, {}),
    },
    {
      key: 'yearlyPrice',
      feature: 'Yearly Price',
      ...pricingData.reduce((acc, plan) => {
        acc[plan.id] = `$${plan.yearlyPrice.toFixed(2)}`;
        return acc;
      }, {}),
    },
    {
      key: 'savings',
      feature: 'Savings per Year',
      ...pricingData.reduce((acc, plan) => {
        acc[plan.id] = plan.savings > 0 ? `$${plan.savings.toFixed(2)}` : <FaTimes color="gray" />;
        return acc;
      }, {}),
    },
    {
      key: 'buttons',
      feature: '',
      ...pricingData.reduce((acc, plan) => {
        acc[plan.id] = true;
        return acc;
      }, {}),
    },
  ];

  return (
    <StyledPlansComparisonTable>
      <div className={b()}>
        <div className={b('heading')}>
          <Heading1>Plan Comparison</Heading1>
        </div>
        <Table columns={columns} dataSource={dataSource} pagination={false} rowHoverable={false} />
      </div>
    </StyledPlansComparisonTable>
  );
};

export default PlansComparisonTable;
