import styled from 'styled-components';

export const StyledGradientButton = styled.div`
  .gradient-button {
    cursor: pointer;
    display: inline-block;
    background: transparent;
    color: ${({ theme }) => theme.colors.mainOrange};
    border: 1px solid ${({ theme }) => theme.colors.mainOrange};
    border-radius: 8px;
    padding: 14px 30px 14px 30px;
    transition: all 0.7s ease-out;
    background: linear-gradient(
      270deg,
      rgba(58, 107, 161, 0.8),
      rgba(31, 69, 117, 0.8),
      rgba(34, 34, 34, 0),
      rgba(34, 34, 34, 0)
    );
    background-position: 1% 50%;
    background-size: 300% 300%;

    &--active,
    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.mainBlue};
      color: ${({ theme }) => theme.colors.white};
      background-position: 99% 50%;
    }

    &--variant-secondary {
      color: ${({ theme }) => theme.colors.mainBlue};
      border: 1px solid ${({ theme }) => theme.colors.mainBlue};
      background: linear-gradient(
        270deg,
        rgba(235, 142, 112, 0.8),
        rgba(217, 87, 37, 0.8),
        rgba(34, 34, 34, 0),
        rgba(34, 34, 34, 0)
      );
      background-position: 1% 50%;
      background-size: 300% 300%;

      &--active,
      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.colors.mainOrange};
        color: ${({ theme }) => theme.colors.mainBlue};
      }
    }
  }
`;
