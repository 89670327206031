import React, { useCallback, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { FlipCountDown } from '@src/components/organisms/flip-count-down';
import { useSignUpMutation } from '@src/store/queries/auth';
import {
  selectOnboardingCurrentStep,
  selectOnboardingUserData,
  setOnboardingCurrentStep,
  setOnboardingUserData,
} from '@src/store/slices';
import { Heading2, Heading4, Paragraph1 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { HealthFitnessGoalsForm } from './health-fitness-goals-form';
import { HealthInformationForm } from './health-information-form';
import { LifeStyleHabitsForm } from './life-style-habits-form';
import { MetricsForm } from './metrics-form';
import { PersonalInformationForm } from './personal-information-form';
import { StyledOnboardingPage } from './styles';

const b = bemClassName('onboarding-page');

const OnboardingPage = () => {
  const dispatch = useDispatch();
  const flipCountDownRef = useRef();
  const userData = useSelector(selectOnboardingUserData);
  const currentStep = useSelector(selectOnboardingCurrentStep);

  const [
    signUp,
    { isLoading: isLoadingSignUp, isUninitialized: isUninitializedSignUp, isError: isErrorSignUp, reset: resetSignUp },
  ] = useSignUpMutation();

  useEffect(() => {
    if (!isLoadingSignUp && !isUninitializedSignUp) {
      if (isErrorSignUp) {
        // TODO: toast and maybe more error handling
      } else {
        // TODO: toast and maybe more success handling
      }

      resetSignUp();
    }
  }, [isLoadingSignUp, isUninitializedSignUp, isErrorSignUp, resetSignUp]);

  useEffect(() => {
    if (currentStep) {
      flipCountDownRef.current.triggerTick(currentStep);
    }
  }, [currentStep]);

  const handleStepFormSubmit = useCallback(
    (values) => {
      dispatch(setOnboardingUserData({ ...userData, ...values }));
      dispatch(setOnboardingCurrentStep(currentStep + 1));
    },
    [currentStep, dispatch, userData],
  );

  const handleStepBack = useCallback(() => {
    dispatch(setOnboardingCurrentStep(currentStep - 1));
  }, [currentStep, dispatch]);

  return (
    <StyledOnboardingPage>
      <div className={b()}>
        <div className={b('heading')}>
          <Heading2>Onboarding</Heading2>
          <Paragraph1>
            Welcome to our fitness and weight loss platform! To provide you with a personalized experience, we&apos;ll
            collect only the essential information. This helps us tailor our services to fit your unique needs and
            preferences. Our onboarding process is quick and straightforward, ensuring we gather just enough data to
            customize your diet plans, workout routines, and other wellness activities. With your input, we can deliver
            the best advice and support to help you achieve your fitness goals and enjoy a healthier lifestyle.
            Let&apos;s get started on this journey together!
          </Paragraph1>
          <FlipCountDown ref={flipCountDownRef} initialNumber={1} />
        </div>

        <div className={b('form')}>
          {currentStep === 1 && (
            <>
              <div className={b('form', 'title')}>
                <Heading4>Personal Information</Heading4>
              </div>
              <PersonalInformationForm onSubmit={handleStepFormSubmit} />
            </>
          )}
          {currentStep === 2 && (
            <>
              <div className={b('form', 'title')}>
                <Heading4>Metrics</Heading4>
              </div>
              <MetricsForm onSubmit={handleStepFormSubmit} onBack={handleStepBack} />
            </>
          )}
          {currentStep === 3 && (
            <>
              <div className={b('form', 'title')}>
                <Heading4>Health And Fitness Goals</Heading4>
              </div>
              <HealthFitnessGoalsForm onSubmit={handleStepFormSubmit} onBack={handleStepBack} />
            </>
          )}
          {currentStep === 4 && (
            <>
              <div className={b('form', 'title')}>
                <Heading4>Lifestyle and Habits</Heading4>
              </div>
              <LifeStyleHabitsForm onSubmit={handleStepFormSubmit} onBack={handleStepBack} />
            </>
          )}
          {currentStep === 5 && (
            <>
              <div className={b('form', 'title')}>
                <Heading4>Health Information</Heading4>
              </div>
              <HealthInformationForm onSubmit={handleStepFormSubmit} onBack={handleStepBack} />
            </>
          )}
        </div>
      </div>
    </StyledOnboardingPage>
  );
};

export default OnboardingPage;
